$base-color: #0033a0;

@font-face {
    font-family: Aeonik;
    src: url(../../assets/fonts/Aeonik-Regular.otf) format("truetype");
}
.my-2 {
    margin-top: 10%;
    margin-bottom: 0%;
}
.csr {
    font-family: Aeonik;
    color: #666d80;
    padding-bottom: 40px;
    .csr-container {
        width: 50%;
        // margin: 50px auto 0px auto;
        margin-bottom: 0px !important;
        padding: 30px;
        font-size: 20px;
        background-color: #dcf3f9;
        .title {
            font-family: "Dela Gothic One", cursive;
            font-size: 30px;
        }
        div {
            margin: 20px 0 20px 0;
            margin-bottom: 0px !important;
            color: $base-color;
            font-family: "Work Sans", sans-serif;
        }
    }
    .container {
        // background-color: #f7f7f7;
        position: relative;
    }
    .flex {
        // background-color: #f7f7f7;
        display: flex;
        width: 87%;
        justify-content: space-around;
        margin-left: auto;
        margin-right: auto;
        padding: 7% 0px 0% 0px;
        img {
            width: 544px;
            height: 500px;
        }
    }
    .single {
        display: flex;
        justify-content: flex-end;
        margin-right: 90px;
        img {
            width: 640px;
            height: 500px;
        }
    }
    @media (max-width: 600px) {
        margin-top: 60px;
        .flex {
            display: block;
            margin-bottom: 30px;
            img {
                width: 100%;
                height: auto;
                // padding-top: 10%;
            }
        }
        .single {
            margin-right: 25px;
            img {
                width: 93%;
                height: 50%;
            }
        }
        .csr-container {
            width: 88%;
            padding: 20px;
            margin-top: -10px;
            padding-bottom: 50px;
            font-size: 15px;
        }
    }
}
