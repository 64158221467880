// @font-face {
//     font-family: AeonikBold;
//     src: url(../../assets/fonts/Aeonik-Bold.otf) format("truetype");
// }
.carousel {
    .carousel-inner {
        // background-color: rgb(82, 76, 76);
        display: flex;
        position: relative;
        justify-content: center;
        align-items: center;
        flex-wrap: nowrap;
        overflow: hidden;
        width: 100%;
        height: 100vh;
        background-image: url(../../assets/paxoil-oil-and-gas-hero-section.png);
        .left,
        .right {
            position: absolute;
            top: 79%;
            z-index: 10;
            cursor: pointer;
        }
        .left {
            left: 20px;
            transform: rotate(180deg);
        }
        .right {
            right: 20px;
        }
        .image {
            width: 100%;
            -webkit-transition: opacity 1s ease-in-out;
            -moz-transition: opacity 1s ease-in-out;
            -o-transition: opacity 1s ease-in-out;
            transition: opacity 1s ease-in-out;
        }
        .slide {
            opacity: 0;
            transition-duration: 5s ease;
            // -webkit-transition: opacity 1s ease-in-out;
            // -moz-transition: opacity 1s ease-in-out;
            // -o-transition: opacity 1s ease-in-out;
            // transition: opacity 1s ease-in-out;
        }

        .slide.active {
            opacity: 1;
            transition-duration: 5s;
            transform: scale(1);
            -webkit-transition: opacity 1s ease-in-out;
            -moz-transition: opacity 1s ease-in-out;
            -o-transition: opacity 1s ease-in-out;
            transition: opacity 1s ease-in-out;
        }
        .overlay {
            position: absolute;
            width: 50%;
            top: 40%;
            left: 7%;
            color: white;
            font-family: "Work Sans", sans-serif;
            .title {
                font-size: 40px;
                padding-bottom: 10px;
                // font-weight: 700;
                font-family: "Dela Gothic One", cursive;
            }
        }
        .flex {
            display: flex;
            justify-content: space-between;
            margin-top: 20%;
            position: absolute;
            top: 38%;
            color: white;
            left: 7%;
            width: 88%;
            cursor: pointer;
            div {
                width: 90%;
            }
            .line {
                height: 1px;
                // width: 200%;
                background-color: #fff;
                margin-bottom: 10px;
            }
        }
        @media (max-width: 600px) {
            height: auto;
            .image {
                transform: scale(1.2);
                height: 50vh;
                width: 100%;
            }
            .left,
            .right {
                display: none;
            }
            .overlay {
                top: 14%;
                width: 80%;
                .title {
                    font-size: 30px;
                    padding-bottom: 3px;
                }
                .desc {
                    font-size: 12px;
                    margin-top: 12px;
                }
            }
            .flex {
                top: 72%;
                .name {
                    display: none;
                }
            }
        }
    }
}
