$base-color: #0033a0;
$light: #dcf3f9;

@font-face {
    font-family: Aeonik;
    src: url(../../assets/fonts/Aeonik-Regular.otf) format("truetype");
}
.safety {
    padding: 80px;
    padding-bottom: 5% !important;
    display: flex;
    justify-content: center !important;
    margin-top: 5%;
    .first {
        width: 90%;
        background-color: $light;
        padding: 50px;
        margin-right: 15px;
        div {
            margin: 20px 0 20px 0;
            color: $base-color;
            font-size: 18px;
            font-family: "Work Sans", sans-serif;
        }
        .title {
            font-size: 30px;
            color: $base-color;
            font-family: "Dela Gothic One", cursive;
        }
    }
    .second {
        width: 60%;
        display: flex;
        justify-content: flex-end;
        img {
            width: 90%;
            height: 80%;
        }
        // .overlay {
        //     position: absolute;
        //     top: 40%;
        //     right: 10%;
        //     background-color: white;
        //     padding: 10px;
        // }
    }
    @media (max-width: 600px) {
        padding: 20px;
        display: block;
        margin-bottom: 10%;
        margin-top: 0px;
        .first {
            width: 88%;
            margin-left: 0px;
            padding: 20px;
        }
        .second {
            width: 100%;
            margin-top: 30px;
            img {
                width: 100%;
            }
            .overlay {
                display: none;
            }
        }
    }
}
