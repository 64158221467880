$base-color: #0033a0;

* {
    margin: 0px;
    padding: 0px;
    max-width: 100%!important;
    overflow-x: hidden;
}
.my-2 {
    margin-top: 10px;
    margin-bottom: 10px;
}
