// @font-face {
//     font-family: AeonikBold;
//     src: url(../../assets/fonts/Aeonik-Bold.otf) format("truetype");
// }
.hero {
    .hero-content {
        img {
            width: 100%;
            height: 87vh;
        }
        .overlay {
            font-family: "Dela Gothic One", cursive;
            position: absolute;
            top: 50%;
            color: white;
            font-weight: 200;
            font-size: 30px;
            width: 50%;
            left: 6%;
        }
        @media (max-width: 600px) {
            margin-bottom: 40px;
            img {
                height: 30vh;
            }
            .overlay {
                top: 38%;
                background-color: #dcf3f9;
                width: 90.4%;
                left: 0%;
                height: auto;
                // text-align: center;
                padding: 20px;
                // left: -89px !important;
                h1 {
                    font-size: 25px;
                    color: #0033a0;
                }
            }
        }
    }
}
