$base-color: #0033a0;

@font-face {
    font-family: Aeonik;
    src: url(../../assets/fonts/Aeonik-Regular.otf) format("truetype");
}
.my-2 {
    margin-top: 10%;
    margin-bottom: 10%;
}

.contact {
    padding: 40px 80px;
    // padding-bottom: 0px;
    font-family: Aeonik;
    .container {
        display: flex;
        background-color: #dcf3f9;
        padding: 80px;
        justify-content: space-evenly;
    }
    .title {
        font-size: 30px;
        text-align: center;
        font-family: "Dela Gothic One", cursive;
        color: #0033a0;
        width: 60%;
        margin: 50px auto;
    }
    .card {
        width: 25%;
        margin: 10px;
        text-align: center;
        .title1 {
            color: $base-color;
            font-size: 25px;
            font-family: "Dela Gothic One", cursive;
        }
        .desc {
            color: $base-color;
        }
        .line {
            height: 1px;
            background-color: $base-color;
            margin: 12px 0 12px 0;
        }
    }
    @media (max-width: 600px) {
        margin-top: 15px;

        .container {
            width: 100%;
            flex-direction: column;
            padding: 0px;
            padding: 20px 0px;
        }
        .title {
            font-size: 20px;
            width: 100%;
            margin-top: 10px;
        }
        display: block;
        .card {
            width: 89%;
            padding: 10px;
            // margin: 35px 0px 30px 0px;
        }
        .weird {
            width: 100%;
        }
        padding: 20px;
    }
}
