$base-color: #0033a0;

// @font-face {
//     font-family: Aeonik;
//     src: url(../../assets/fonts/Aeonik-Regular.otf) format("truetype");
// }
.home {
    font-family: Aeonik;
    .flex {
        display: flex;
        position: relative;
        .first {
            width: 40%;
            background-color: #dcf3f9;
            color: $base-color;
            padding: 130px 200px 60px 130px;
            // height: 65vh;
            .title {
                font-size: 30px;
                margin-bottom: 20px;
                font-family: "Dela Gothic One", cursive;
            }
            .desc {
                font-size: 18px;
                margin-bottom: 30px;
                font-family: "Work Sans", sans-serif;
            }
            a {
                text-decoration: none;
            }
            button {
                display: flex;
                // padding: 15px;
                font-size: 18px;
                border: none;
                // border-radius: 30px;
                color: #e4002b;
                font-family: "Dela Gothic One", cursive;
                background: transparent;
                font-weight: 700;
                // padding-top: 20px;
                div {
                    margin: 4px 10px 0px 10px;
                }
            }
        }
        .second {
            width: 40%;
            // background-color: #f7f7f7;
            img {
                width: 35%;
                position: absolute;
                top: 70px;
                right: 10%;
            }
        }
        @media (max-width: 600px) {
            display: block;
            .first {
                padding: 20px;
                width: 90%;
                padding-top: 40px;
                height: auto;
                padding-bottom: 40px;
                .title {
                    font-size: 25px;
                }
                .desc {
                    font-size: 15px;
                }
                button {
                    padding: 10px;
                    padding-top: 12px;
                }
            }
            .second {
                width: 100%;
                padding: 20px;
                padding-top: 0px;
                img {
                    width: 90%;
                    position: relative;
                    margin-bottom: 60px;
                    right: 0px;
                    top: 40px;
                }
            }
        }
    }
    .first {
        padding-top: 70px;
    }
    .services {
        display: flex;
        // margin: 0px 60px 0px 60px;
        justify-content: space-between;
        .weird {
            margin-right: 50px;
        }
        .second {
            width: 50%;
            padding: 120px 0px 20px 80px;
            .title {
                color: $base-color;
                font-size: 30px;
                margin-bottom: 20px;
                font-family: "Dela Gothic One", cursive;
            }
            .desc {
                font-size: 18px;
                margin-bottom: 30px;
                font-family: "Work Sans", sans-serif;
            }
            a {
                text-decoration: none;
            }
            button {
                display: flex;
                // padding: 13px;
                font-size: 18px;
                color: #e4002b;
                font-weight: 700;
                border: none;
                border-radius: 30px;
                background: transparent;
                font-family: "Dela Gothic One", cursive;
                div {
                    margin: 4px 5px 0px 5px;
                }
            }
        }
        @media (max-width: 600px) {
            display: block;
            padding: 20px;
            padding-right: 25px;
            margin: 0px;
            margin-top: 20px;
            img {
                width: 102%;
                // margin:10px;
            }
            .weird {
                margin-top: -35px;
                // z-index: 10;
            }
            .second {
                width: 100%;
                padding: 10px;
                padding-bottom: 0px;
                .desc {
                    font-size: 15px;
                }
            }
        }
    }
    .hsp {
        padding: 0px 60px;
        position: relative;
        .container {
            width: 97%;
            margin: 0px auto;
            height: 100vh;
            background-color: #dcf3f9;
        }
        .hsp-container {
            position: absolute;
            width: 38%;
            bottom: 0px;
            right: 10%;
        }
        .overlay {
            position: absolute;
            top: 22%;
            left: 15px;
            display: flex;
            justify-content: center;
            .qoute {
                width: 40% !important;
                font-family: "Work Sans", sans-serif;
                .coma {
                    font-size: 60px;
                    font-weight: 900;
                }
                a {
                    text-decoration: none;
                }
                button {
                    display: flex;
                    padding: 1px;
                    font-size: 18px;
                    border: none;
                    border-radius: 30px;
                    color: #e4002b;
                    background: transparent;
                    font-weight: 700;
                    font-family: "Dela Gothic One", cursive;
                    margin: 10px 0px;
                    padding-top: 18px;
                    div {
                        margin: 5px 10px 0px 10px;
                    }
                }
                font-size: 45px;
                color: $base-color;
                width: 50%;
                .author {
                    font-size: 20px;
                    padding: 20px 0px;
                }
            }

            .second {
                width: 25%;
                z-index: 1;
                background-color: white;
                box-shadow: 0px 4px 4px 0px #00000040;
                opacity: 90%;
                padding: 40px;
                border-radius: 5px;
                .title {
                    font-size: 30px;
                    width: 80%;
                    margin-bottom: 15px;
                    color: $base-color;
                    font-family: "Dela Gothic One", cursive;
                }
                .line {
                    width: 50%;
                    height: 1px;
                    background-color: $base-color;
                    margin-bottom: 15px;
                }
                .desc {
                    font-size: 20px;
                    margin-top: 20px;
                    color: #666d80;
                    font-family: "Work Sans", sans-serif;
                }
            }
        }
        @media (max-width: 600px) {
            padding: 15px;
            padding-bottom: 0px;
            overflow: hidden;
            height: 95vh;
            .hsp-container {
                display: none;
            }
            .overlay {
                flex-direction: column;
                top: 5px;
                height: 100vh;
                overflow: hidden;
                .qoute {
                    font-size: 25px;
                    width: 80% !important;
                    padding: 30px;
                    order: 1;
                    .coma {
                        font-size: 30px;
                    }
                    .author {
                        font-size: 12px;
                        padding: 10px 0px;
                    }
                }
                .second {
                    width: 60%;
                    margin: 26px 17px;
                    opacity: 100%;
                    order: 0;
                    .title {
                        font-size: 20px;
                    }
                    .desc {
                        font-size: 15px;
                    }
                }
            }
        }
    }
}
