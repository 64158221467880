$base-color: #0033a0;

@font-face {
    font-family: Aeonik;
    src: url(../../assets/fonts/Aeonik-Regular.otf) format("truetype");
}
.my-2 {
    margin-top: 10%;
    margin-bottom: 10%;
}
.about {
    font-family: Aeonik;
    .about-content {
        padding: 80px;
        // padding-bottom: -50px;
        .flex {
            display: flex;
            justify-content: space-evenly;
            .left {
                width: 40%;
                .title {
                    color: $base-color;
                    font-size: 35px;
                    margin-top: 20px;
                    font-family: "Dela Gothic One", cursive;
                }
                .text {
                    color: #666d80;
                    margin: 30px 0px 30px 0px;
                    font-size: 20px;
                    font-family: "Work Sans", sans-serif;
                }
            }
            .card {
                padding: 0px;
                margin-top: auto;
                margin-bottom: auto;
                width: 60%;
                .title {
                    color: $base-color;
                    font-size: 25px;
                    margin-bottom: 10px;
                    font-family: "Dela Gothic One", cursive;
                }
                .description {
                    color: #666d80;
                    font-family: "Work Sans", sans-serif;
                }
            }
        }
        .vision {
            display: flex;
            background-color: #dcf3f9;
            padding: 100px 50px;
            justify-content: space-evenly;
            align-items: center;
            margin: 40px 0px;
            height: 400px;
            overflow: hidden;
            .text {
                color: $base-color;
                width: 30%;

                .title {
                    font-size: 20px;
                    margin-bottom: 5px;
                    font-family: "Dela Gothic One", cursive;
                }
                .desc {
                    font-size: 30px;
                    font-family: "Work Sans", sans-serif;
                }
            }
            .img {
                width: 50%;
                .mission {
                    width: 80%;
                    margin-left: 60px;
                }
            }
        }
        @media (max-width: 600px) {
            .flex {
                display: block;
                .left {
                    width: 100%;
                    .title {
                        font-size: 25px;
                    }
                    .text {
                        font-size: 15px;
                    }
                }
                img {
                    width: 100%;
                }
            }
            .vision {
                flex-direction: column;
                padding: 30px;
                .text {
                    width: 100%;
                    .title {
                        font-size: 15px;
                    }
                    .desc {
                        font-size: 25px;
                    }
                }
                .img {
                    width: 100%;
                    margin-top: 20px;
                    .mission{
                        margin-left: 20px;
                    }
                }
            }
        }
    }
    @media (max-width: 600px) {
        .about-content {
            padding: 20px;
        }
    }
    .flex2 {
        display: flex;
        .flex {
            width: 50%;
        }
    }
    .values {
        padding-top: 0px !important;
        padding-bottom: 40px !important;
        padding: 80px;
        .heading {
            font-family: "Dela Gothic One", cursive;
            color: $base-color;
            font-size: 32px;
            text-align: center;
            padding-bottom: 25px;
        }
        .container {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            .card {
                background-color: #dcf3f9;
                color: $base-color;
                width: 30%;
                padding: 60px 10px 60px 10px;
                margin: 20px 0 20px 0;
                border-radius: 10px;
                div {
                    display: flex;
                    justify-content: center;
                }
                .title {
                    font-size: 25px;
                    padding: 10px;
                    font-family: "Dela Gothic One", cursive;
                }
                .desc {
                    text-align: center;
                    padding: 30px;
                    font-family: "Work Sans", sans-serif;
                }
            }
        }
        @media (max-width: 600px) {
            .heading {
                padding: 10px 0 20px 0;
            }
            .container {
                .card {
                    margin: 15px 0px 15px 0px;
                    padding: 15px;
                    width: 100%;
                }
            }
        }
    }
    @media (max-width: 600px) {
        .values {
            padding: 20px;
        }
        .flex2 {
            display: block;
            .flex {
                width: 100%;
                .card {
                    padding: 20px;
                    padding-bottom: 30px;
                }
            }
        }
    }
}
