$base-color: #0033a0;

@font-face {
    font-family: Aeonik;
    src: url(../../assets/fonts/Aeonik-Regular.otf) format("truetype");
}
ul li {
    list-style-type: square;
    margin-top: 7px;
}
// ::marker {
//     color: red;
//     font-size: 22px;
// }
.services {
    padding: 80px;
    padding-bottom: 40px;
    font-family: Aeonik;
    color: #666d80;
 
    .services-container {
        display: flex;
        justify-content: space-between;
        .left {
            width: 40%;
            overflow: hidden;
            .overlay-bottom {
                width: 60%;
                height: 30%;
                background-color: #dcf3f9;
                position: relative;
                top: -15%;
                left: 60px;
            }
            .overlay-top {
                position: relative;
                top: -28%;
                left: 100px;
                width: 80%;
            }
        }
        .right {
            width: 50%;
            margin-top: 5px;
            .weird {
                .title {
                    font-size: 35px;
                    color: $base-color;
                    font-family: "Dela Gothic One", cursive;
                }
                div {
                    margin: 25px 0px 25px 0;
                    font-size: 18px;
                    font-family: "Work Sans", sans-serif;
                }
            }
            .container {
                margin-top: 30px;
                .list {
                    .line {
                        height: 1px;
                        background-color: $base-color;
                        margin: 10px 0px 20px 0;
                        // width: 100%;
                    }
                    .desc {
                        color: #666d80;
                    }
                    .title {
                        font-size: 20px;
                        color: $base-color;
                        font-family: "Work Sans", sans-serif;
                    }
                    .icon {
                        color: $base-color;
                    }
                }
            }
        }
    }
    @media (max-width: 600px) {
        .services-container {
            display: block;
            .left {
                width: 100%;
                position: relative;
                margin-top: 20px;
                img {
                    height: 350px;
                    width: 100%;
                }
                .overlay-bottom {
                    top: 50%;
                    left: 40px;
                    position: absolute;
                }
                .overlay-top {
                    height: auto;
                }
            }
            .right {
                width: 100%;
                .weird {
                    div {
                        margin-top: 12px;
                    }
                    .title {
                        font-size: 25px;
                    }
                }
                img {
                    width: 100%;
                }
                .list {
                    .title {
                        font-size: 20px;
                    }
                }
            }
        }
        padding: 20px;
    }
}
