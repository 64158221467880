$base-color: #0033a0;
// @font-face {
//     font-family: Aeonik;
//     src: url(../../assets/fonts/Aeonik-Regular.otf) format("truetype");
// }
// @font-face {
//     font-family: AeonikBold;
//     src: url(../../assets/fonts/Aeonik-Bold.otf) format("truetype");
// }
.Header {
    .webNav {
        display: block;
        display: flex;
        margin-right: 60px;
        margin-left: 60px;
        padding: 20px;
        justify-content: space-between;

        img {
            width: 75%;
        }
        ul {
            display: flex;
            list-style-type: none;
            font-family: "Dela Gothic One";
            font-weight: normal;
            li {
                padding: 10px 20px;
                a {
                    text-decoration: none;
                    text-transform: uppercase;
                    font-size: 15px;
                    color: $base-color;
                }
            }
        }
    }
    .mobileNav {
        display: none;
        // img {
        //     width: 90px;
        // }
        .toggler {
            padding: 20px;
            display: flex;
            justify-content: space-between;
            #logo {
                margin-right: 90px;
                width: 120px;
            }
        }
        #nav {
            width: 30px;
            height: 15px;
            margin-top: 6px;
        }
        ul {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            position: fixed;
            width: 100vw;
            background-color: white;
            height: 100vh;
            font-size: 18px;
            list-style-type: none;
            font-family: Aeonik;
            font-weight: normal;
            z-index: 1;
            li {
                color: $base-color;
                padding: 10px;
                a {
                    text-decoration: none;
                    text-transform: uppercase;
                    font-family: "Dela Gothic One";
                    color: $base-color;
                }
                font-weight: thin;
                font-family: "Work Sans", sans-serif;
            }
            hr {
                width: 200px;
                margin-top: 10px;
                margin-bottom: 10px;
                background-color: $base-color !important;
                color: $base-color !important;
            }
            .mobile-close {
                display: flex;
                justify-content: flex-end;
                width: 100%;
                margin-bottom: 40px;
                // padding: 20px;
                position: absolute;
                top: 0px;
                #logo2 {
                    width: 130px;
                }
                #close {
                    width: 15px;
                    height: 15px;
                    margin-top: 23px;
                    margin-right: 40px;
                }
            }
        }
    }
    $breakpoint-tablet: 768px;
    @media (max-width: $breakpoint-tablet) {
        .webNav {
            display: none;
        }
        .mobileNav {
            display: block;
        }
    }
}

.footer {
    background-color: #0033a0;
    padding: 50px 80px;
    margin-top: 40px;
    a {
        color: white;
        text-decoration: none;
    }
    .footer-container {
        // margin-top: 10px;
        display: flex;
        justify-content: space-between;

        .first,
        .second {
            width: 25%;
            span {
                color: #fff;
                font-size: 15px;
                font-family: "Work Sans";
                padding-top: 30px;
            }
            img {
                margin-bottom: 40%;
                @media (max-width: 600px) {
                    margin-top: 10%;
                    margin-bottom: 10%;
                }
            }
            .flex {
                display: flex;
                justify-content: space-evenly;
            }
        }
        // .second {
        //     margin-top: 40px;
        // }
    }
    @media (max-width: 600px) {
        padding: 20px;
        .footer-container {
            flex-direction: column;
            text-align: center;
            margin-top: 20px;
            .first {
                img {
                    width: 150px;
                    margin-bottom: 15px;
                }
            }
            .first,
            .second {
                width: 100%;
                .flex {
                    width: 70%;
                    margin-left: auto;
                    margin-right: auto;
                    // padding: 30px;
                    padding-bottom: 5px;
                    #fb {
                        width: 21px !important;
                    }
                    img {
                        width: 25px;
                        height: 25px;
                    }
                }
            }
        }
    }
}
